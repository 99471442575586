import { useState } from "react";
import { Outlet } from "react-router-dom";
import AuthProvider from "../components/AuthProvider";
import ConfigProvider from '../components/ConfigProvider';

import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import Navigation from "./Navigation";
import BreadcrumbNavigation from "./BreadcrumbNavigation";

const MainLayout = ({}) => {
  const drawerWidth = 256;
  const [navOpen, setNavOpen] = useState(true);

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
  };

  return (
    <>
      <AuthProvider>
        <ConfigProvider>
          <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
            <CssBaseline />
            <AppBar
              color="default"
              position="fixed"
              sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
              }}
            >
              <Toolbar>
                <IconButton
                  onClick={handleNavToggle}
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  <BreadcrumbNavigation />
                </Typography>
              </Toolbar>
            </AppBar>
            <Box
              component="nav"
              aria-label="navigation"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
              <Drawer
                variant="temporary"
                open={navOpen}
                onClose={handleNavToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                <Navigation />
              </Drawer>
              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                open
              >
                <Navigation />
              </Drawer>
            </Box>
            <Box
              component="main"
              sx={{ overflow: "auto", display: "flex", flexDirection: "column", width: "100%", flexGrow: 1, px: {xs: 2, sm: 3}, pt: { xs: 2, sm: 8 }, pb: { xs: 1, sm: 2 }}}
            >
              <Toolbar />
              <Outlet />
            </Box>
          </Box>
        </ConfigProvider>
      </AuthProvider>
    </>
  );
};

export default MainLayout;


import { Divider, List, Toolbar } from "@mui/material";
import menuItems from "../menu-items";
import NavItem from "./NavItem";

import Logo from '../components/Logo';

const Navigation = () => {
    const nav = menuItems.items.map((item) => {
        return <NavItem key={item.id} item={item} level={1} />;
    });

    return (
        <div>
            <Toolbar>
                <Logo />
            </Toolbar>
            <Divider />
            <List>{nav}</List>
        </div>
    );
};

export default Navigation;

import useNotification from "./useNotification";

import { Box, Grid, Stack, Typography, Snackbar, Alert } from '@mui/material';

const NotificationBar = () => {
    const { notifications, removeNotification } = useNotification();

    const handleClose = (notification, reason) => {
        if(reason && reason === 'clickaway') return;
        removeNotification(notification);
    };

    return (
        <Box sx={{ position: 'fixed', bottom: '2rem', right: '2rem', zIndex: 9999 }}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                {notifications.map((notification) => (
                    <Snackbar key={notification.id} open={true} autoHideDuration={6000} onClose={(event, reason) => handleClose(notification, reason)} sx={{ position: 'static' }}>
                        <Alert onClose={() => handleClose(notification)} severity={notification.type} sx={{ width: '100%' }}>
                            {notification.message}
                        </Alert>
                    </Snackbar>
                ))}
            </Stack>
        </Box>
    );
};

export default NotificationBar;
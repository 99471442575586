import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('../pages/Login')));
const NotFound = Loadable(lazy(() => import('../pages/NotFound')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'logout',
            element: <AuthLogin logout={true} />
        },
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default LoginRoutes;



import React, { useState } from 'react';
import NotificationContext from './Context';

const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addSuccessNotification = (message) => {
        addNotification({
            id: Math.random().toString(36).slice(2),
            message,
            type: 'success'
        });
    };

    const addErrorNotification = (message) => {
        addNotification({
            id: Math.random().toString(36).slice(2),
            message,
            type: 'error'
        });
    }

    const addInfoNotification = (message) => {
        addNotification({
            id: Math.random().toString(36).slice(2),
            message,
            type: 'info'
        });
    }

    const addNormalNotification = (message) => {
        addNotification({
            id: Math.random().toString(36).slice(2),
            message,
            type: ''
        });
    }

    const addNotification = (notification) => {
        console.log(notifications);
        setNotifications(oldNotifcations => [...oldNotifcations, notification]);
    };

    const removeNotification = (notification) => {
        console.log("remove");
        setNotifications(oldNotifcations => oldNotifcations.filter((item) => item.id !== notification.id));
    };

    const removeAllNotifications = () => {
        setNotifications([]);
    };

    const value = {
        notifications,
        addNotification,
        addSuccessNotification,
        addErrorNotification,
        addInfoNotification,
        addNormalNotification,
        removeNotification,
        removeAllNotifications
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
}

export default NotificationProvider;
import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

// render - login
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Users = Loadable(lazy(() => import('../pages/Users')));
const User = Loadable(lazy(() => import('../pages/User')));

const Requests = Loadable(lazy(() => import('../pages/Requests')));
const Request = Loadable(lazy(() => import('../pages/Request')));

const Companies = Loadable(lazy(() => import('../pages/Companies')));
const Company = Loadable(lazy(() => import('../pages/Company')));

// ==============================|| AUTH ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: "request",
            element: <Requests />
        },
        {
            path: 'request/:id',
            element: <Request />
        },
        {
            path: 'request',
            element: <Requests />
        },
        {
            path: 'company',
            element: <Companies />
        },
        {
            path: 'company/:id',
            element: <Company />
        },
        {
            path: 'user',
            element: <Users />
        },
        {
            path: 'user/:id',
            element: <User />
        },
    ]
};

export default MainRoutes;

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import menuItems from "../menu-items";

const BreadcrumbNavigation = () => {
    const location = useLocation();
    const foundItem = useMemo(() => {
        const foundItem = menuItems.items.find((item) => {
            return item.url === location.pathname;
        });
        return foundItem;
    }, [location.pathname]);

    if(!foundItem) return null;

    return (
        <div>
            {foundItem.title}
        </div>
    );
};

export default BreadcrumbNavigation;
import { DashboardOutlined, LogoutOutlined } from '@ant-design/icons';
import ViewListIcon from '@mui/icons-material/ViewList';

const menuItems = {
    items: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'companies',
            title: 'Companies',
            type: 'item',
            url: '/company',
            icon: ViewListIcon
        },
        {
            id: 'leads',
            title: 'Users',
            type: 'item',
            url: '/user',
            icon: ViewListIcon
        },
        {
            id: 'requests',
            title: 'Requests',
            type: 'item',
            url: '/request',
            icon: ViewListIcon
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: '/logout',
            icon: LogoutOutlined
        }
    ]
};

export default menuItems;
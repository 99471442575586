import { createContext, useEffect, useState } from 'react';
import useRequest from '../utils/useRequest';
import useNotification from './Notification/useNotification';

const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState(null);
    const { request } = useRequest();
    const { addErrorNotification } = useNotification();

    const loadConfig = async () => {
        try {
            const json = await request('/config', {}, {});

            if(json.r == 1){
                const statusList = [
                    { _id: 0, name: 'Waiting', color: '#eeeeee' },
                    { _id: 1, name: 'Finished', color: '#00ff00'},
                    { _id: 5, name: 'In progress', color: '#fff200' },
                    { _id: 10, name: 'Error', color: '#ff0000'},
                ]
                setConfig({...json.config, taskStatusList: statusList});
            } else {
                addErrorNotification(json.message);
            }
            
        } catch (error) {
            console.error(error);
            addErrorNotification("Loading Config server error: " + error.message);
        }
    };

    useEffect(() => {
        loadConfig();
    }, []);

    const value = {
        config,
    };

    if(!config) return null;

    return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
}

export default ConfigProvider;
export { ConfigContext };
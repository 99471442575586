import logo from "./logo.svg";
import "./App.css";

import Routes from "./routes";
//import ThemeCustomization from './themes';

import NotificationProvider from "./components/Notification/NotificationProvider";
import NotificationBar from "./components/Notification/NotificationBar";

function App() {
  return (
    <NotificationProvider>
      <NotificationBar />
      <Routes />
    </NotificationProvider>
  );
}

export default App;


import { Button } from '@mui/material';

import Logo from './Logo';

const LogoSection = ({ sx, to }) => (
    <Button target="_blank" href="https://quoter.ai" sx={{ p: 0, ...sx }}>
        <Logo />
    </Button>
);

export default LogoSection;
